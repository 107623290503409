<template>
	<Header title="سياسات الشركة" link="سياسة الجودة" />
	<div class="bg-img">
		<div class="container mx-auto my-12 px-5 md:px-8 text-right">
			<h3 class="text-xl text-black font-bold ">سياســة الجــــودة</h3>
			<div class="flex items-center flex-wrap md:flex-nowrap my-8">
				<img src="../assets/isoimage.png" alt="" />
				<p class="mx-5 text-infoGray tracking-wide my-5">
					ان شركة زين لخدمات التموين والنظافة والسفرجة منذ أن أسست وهي تسعى دائما لتحقيق أهداف معينة لتصل
					بالمستوى المطلوب لكسب رضاء العميل لهذا قد وضعنا نظام إدارة الجودة على أساس متطلبات ومعايير ايزو
					9001-2015. كما أننا نركز تركيزا كبيرا على الاستمرار في تحسين عملياتنا وخدماتنا لتتجاوز متطلبات ورضاء
					العملاء.
				</p>
			</div>
			<p class="text-infoGray my-8 text-center md:text-right">
				شركة زين تتبنى مبدأ الادارة السلمية من خلال بناء عملية التحسين المستمرة داخل الشركة للوصول إلى السياسة
				التالية:-
			</p>
			<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:gap-16 my-16">
				<div v-for="(item, index) in data" :key="index">
					<img :src="item.img" alt="" class="bg-white shadow-lg p-5 rounded-lg mx-auto md:mx-0" />
					<p class="text-termsText text-lg font-medium mt-8 text-center md:text-right">{{ item.p }}</p>
				</div>
			</div>
			<p class="text-infoGray text-lg my-1">
				ان شركة زين تستعرض هذه السياسة و الاهداف بصفة دائمة أثناء الاجتماعات الدورية للمدراء كما أن هناك نظام
				تدريبي متكامل للموظفين لمعرفتها و فهمها وتنفيذها.
			</p>
			<p class="text-infoGray text-lg my-1">
				وانا كمديراً عاما ملتزم التزاما كاملا بضمان تنفيذ هذه السياسة والأهداف والتحسين المستمر لنظام إدارة
				الجودة.
			</p>
			<p class="text-infoGray text-lg my-1">
				ان شركة زين تستعرض هذه السياسة و الاهداف بصفة دائمة أثناء الاجتماعات الدورية للمدراء كما أن هناك نظام
				تدريبي متكامل للموظفين لمعرفتها و فهمها وتنفيذها.
			</p>
			<p class="text-infoGray text-lg my-1">
				وانا كمديراً عاما ملتزم التزاما كاملا بضمان تنفيذ هذه السياسة والأهداف والتحسين المستمر لنظام إدارة
				الجودة.
			</p>
			<span class="block my-8 bg-primary h-1 w-32"></span>
			<h1 class="text-2xl font-bold font-block">المديـــــــــــــــــــر العــام</h1>
			<h1 class="text-2xl font-bold font-block">احمد الرغروغي</h1>
		</div>
	</div>
	<ContactLink />
</template>
<script>
import { defineAsyncComponent } from 'vue';
const ContactLink = defineAsyncComponent(() => import('../components/ContactLink'));
const Header = defineAsyncComponent(() => import('../components/Header'));
import img1 from '../assets/winner (1).png';
import img2 from '../assets/search.png';
import img3 from '../assets/Twotone.png';
import img4 from '../assets/user (1).png';
import img5 from '../assets/garbage.png';
import img6 from '../assets/education.png';
import img7 from '../assets/antivirus.png';
export default {
	name: 'Terms',
	data() {
		return {
			data: [
				{ p: 'خلق ميزة تنافسية لصالح العميل والمشروع كله.', img: img1 },
				{ p: 'مراقبة جميع عمليات التوريد والتركيب  والخدمات لجميع المشاريع.', img: img2 },
				{ p: 'باستمرار إضافة قيمة إلى العمليات وجميع جوانب أعمال النظافة والسفرية.', img: img3 },
				{ p: 'توفير منتجات عالية الجودة والخدمات بشكل كامل لتلبية متطلبات وكسب رضاء  العملاء.', img: img4 },
				{
					p: 'تحديد والقضاء على جميع أشكال النفايات التي لا تحقق قيمة للعميل و للوصول الى بيئة نظيفة.',
					img: img5,
				},
				{
					p:
						'تعزيز مهارات الإداريين والموظفين من خلال التدريب والمراجعة والتي تهدف الى تحسين الاداء بطريقة أكثر فاعلية',
					img: img6,
				},
				{
					p:
						'تعزيز نظام ادارة الجودة وضمان تنفيذه من خلال المراجعات الداخلية واتخاذ جميع الإجراءات التصحيحية والوقائية.',
					img: img7,
				},
			],
		};
	},
	components: { ContactLink, Header },
};
</script>
<style scoped>
.bg-img {
	background: url(../assets/leftfull.png), url(../assets/rightfull.png);
	background-repeat: no-repeat;
	background-position: left -450px center, right -450px center;
}
</style>
